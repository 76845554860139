<template>
  <!--    <div class=" border border-black bg-gray-300 max-w-screen-lg max-auto rounded-xl p-20 text-center mt-4">-->

  <div
    class="border-4 border-gray-600 bg-yellow-200  max-w-screen-lg mx-auto p-20 mt-6 rounded-xl"
  >
    <div class="flex justify-center" v-if="words.length > 0">
      <p class="text-4xl mt-2" v-if="!view_face">
        {{ words[0].word }}
      </p>

      <p class="text-4xl mt-2" v-if="view_face">
        {{ words[1].word }}
      </p>
      <div>
        <div
          v-if="!view_face && words[0].audio != null"
          @click="flashSound(words[0].audio)"
        >
          <img
            class="bg-blue-500 hover:bg-green-600 w-16 h-16 rounded-full
                                transition ease-in duration-700 ml-4"
            src="https://img.icons8.com/ios/50/000000/room-sound.png"
          />
        </div>

        <div
          v-if="!view_face && words[0].audio == null"
          @click="flashSound(words[0].audio)"
        >
          <img
            class="bg-blue-500 w-16 h-16 rounded-full ml-4 cursor-not-allowed"
            src="https://img.icons8.com/ios/50/000000/room-sound.png"
          />
        </div>
      </div>

      <div>
        <div
          v-if="view_face && words[0].audio != null"
          @click="flashSound(words[1].audio)"
        >
          <img
            class="bg-blue-500 hover:bg-green-600 w-16 h-16 rounded-full
                          transition ease-in duration-700 ml-4"
            src="https://img.icons8.com/ios/50/000000/room-sound.png"
          />
        </div>
        <div
          v-if="view_face && words[0].audio == null"
          @click="flashSound(words[1].audio)"
        >
          <img
            class="bg-blue-500 hover:bg-green-600 w-16 h-16 rounded-full ml-4 cursor-not-allowed"
            src="https://img.icons8.com/ios/50/000000/room-sound.png"
          />
        </div>
      </div>
    </div>

    <div class="text-center max-w-screen-lg mx-auto mt-2 rounded-xl">
      <button
        @click="view_face = !view_face"
        class="bg-blue-500 hover:bg-blue-600 text-white text-center font-bold py-2 px-4 rounded mt-4
                             transition ease-in-out duration-700 transform hover:scale-110"
      >
        FlipCard
      </button>

      <button
        @click="pickWord"
        class="bg-blue-500 hover:bg-blue-600 text-white text-center font-bold py-2 px-8 rounded ml-4
                            transition ease-in-out duration-700 transform hover:scale-110"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      view_face: false,

      words: []
    };
  },

  methods: {
    pickWord() {
      this.view_face = false;

      axios
        .get(
          "https://languageapp.watchtheprogress.com/api/card/" +
            localStorage.getItem("language_1") +
            "/" +
            localStorage.getItem("language_2")
        )

        .then(
          response =>
            // console.log("response is: ", response),
            (this.words = response.data.word)
        );
    },

    flashSound(url) {
      var audio = new Audio(url);

      audio.play();
    }
  },

  mounted() {
    if (
      !localStorage.getItem("language_1") ||
      !localStorage.getItem("language_2")
    ) {
      this.$router.push({
        name: "flip-card"
      });
    }

    this.pickWord();
  }
};
</script>

<style scoped></style>
