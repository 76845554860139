<template>
  <div id="app">
    <h2 class="flex justify-center">Learn Language</h2>
    <NavBar class="mt-4" />
    <router-view />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
// import AudioRecorder from "vue-audio-recorder"
export default {
  components: {
    NavBar
  },
  data() {
    return {
      recorder: null
    };
  },

  methods: {}
};
</script>

<style>
#app {
}
</style>
