<template>
  <div
    class="border border-gray-900 bg-yellow-200 max-w-screen-lg m-auto p-20 mt-8 rounded-xl"
  >
    <h1 class="text-center text-3xl">Pick 2 different Language</h1>

    <div class="mt-6 text-center">
      <label>pick a language </label>
      <select v-model="language_1">
        <option disabled value=""> choose one </option>
        <option v-for="word in options" :key="word.id" :value="word.id">
          {{ word.name }}
        </option>
      </select>
    </div>

    <div class="mt-4 text-center">
      <label>pick another language </label>
      <select v-model="language_2">
        <option disabled value=""> choose one </option>
        <option v-for="word in options" :key="word.id" :value="word.id">
          {{ word.name }}
        </option>
      </select>
    </div>

    <div class="flex justify-center mt-2">
      <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded mt-2"
        @click="Go"
      >
        Ready To Learn :)
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      language_1: "",
      language_2: "",

      options: []
    };
  },

  methods: {
    Go() {
      axios
        .get("https://languageapp.watchtheprogress.com/api/languages")
        .then(
          response => (
            // console.log("response is: ", response),
            (this.options = response.data.languages)
          )
        );
      //

      if (this.language_1 == this.language_2) {
        alert("Please Pick Two different Language");

        localStorage.removeItem("language_1", this.language_1);
        this.language_1 = "";

        localStorage.removeItem("language_2", this.language_2);
        this.language_2 = "";
      } else {
        // console.log("Storage language_1 is: ", this.language_1);
        localStorage.setItem("language_1", this.language_1);

        // console.log("Storage language_2 is: ", this.language_2);
        localStorage.setItem("language_2", this.language_2);

        this.$router.push({
          name: "flash-card"
        });
      }
    }
  },

  mounted() {
    this.Go();
  }
};
</script>

<style scoped>
.alert {
}
</style>
