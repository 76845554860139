<template>
  <div
    class="border-4 bg-yellow-200 p-20 text-center max-w-screen-lg mx-auto mt-4 rounded-xl"
  >
    <div>
      <label>Pick a language </label>
      <select v-model="language_id" @change="get_word_without_sound">
        <option disabled value=""> choose one </option>
        <option
          v-for="language in languages"
          :key="language.id"
          :value="language.id"
        >
          {{ language.name }}
        </option>
      </select>
    </div>

    <div class="mt-6">
      <h1
        v-if="word"
        @change="get_word_without_sound"
        class="font-bold text-3xl"
      >
        ( {{ word.word }} )
      </h1>
      <audio-recorder
        v-if="word"
        :upload-url="
          'https://languageapp.watchtheprogress.com/api/words/' + word.id
        "
        filename="audio"
        :attempts="1"
        :time="20"
        :show-download-button="false"
        :successful-upload="get_word_without_sound"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

// import $ from "jquery"

export default {
  data() {
    return {
      word: "",

      language_id: "",

      languages: []
    };
  },

  methods: {
    update_record() {
      // $(".ar__rm").click();

      let x = document.getElementsByClassName("ar__rm");

      if (x.length > 0) {
        x[0].click();
      }
    },

    callback(data) {
      console.debug(data);
    },

    get_word_without_sound() {
      axios
        .get(
          "https://languageapp.watchtheprogress.com/api/word-without-audio/" +
            this.language_id
        )
        .then(response => {
          this.update_record();

          this.word = response.data.word;
        });
    },

    get_language() {
      axios.get("https://languageapp.watchtheprogress.com/api/languages").then(
        response =>
          // console.log("response is: ", response),
          (this.languages = response.data.languages)
      );
    }
  },

  mounted() {
    this.get_language();
  }
};
</script>

<style scoped></style>
