import Vue from "vue";
import VueRouter from "vue-router";
import Learning from "../views/Learning";
import FlipCard from "../views/FlipCard";
import FlashCard from "../components/FlashCard";
import Audio from "@/components/Audio.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "learning",
    component: Learning
  },
  {
    path: "/flip-card",
    name: "flip-card",
    component: FlipCard
  },
  {
    path: "/flash-card",
    name: "flash-card",
    component: FlashCard
  },
  {
    path: "/audio",
    name: "audio",
    component: Audio
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
