<template>
  <div style="text-align: center;">
    <router-link to="/"> Learning </router-link> |
    <router-link :to="{ name: 'flip-card' }"> FlipCard </router-link> |
    <router-link :to="{ name: 'audio' }"> Record </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
