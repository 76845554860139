<template>
  <div class="border bg-gray-100 mx-w-screen-lg mx-auto p-25 rounded-lg">
    <div
      class="border-4 border-gray-600 bg-yellow-200  max-w-screen-lg mx-auto p-20 mt-6 rounded-xl"
    >
      <div v-for="word in words" :key="word.id">
        <div class="grid grid-cols-3 gap-4">
          <div
            v-if="word.audio != null"
            class="col-span-1 flex item-itself justify-center p-6"
            @click="playSound(word.audio)"
          >
            <img
              class="bg-blue-500 hover:bg-green-600 w-16 h-16 rounded-full transition ease-in duration-300"
              src="https://img.icons8.com/ios/50/000000/room-sound.png"
            />
          </div>

          <div
            v-if="word.audio == null"
            class="col-span-1 flex item-itself justify-center p-6 cursor-not-allowed"
            @click="playSound(word.audio)"
          >
            <img
              class="bg-blue-500 w-16 h-16 rounded-full"
              src="https://img.icons8.com/ios/50/000000/room-sound.png"
            />
          </div>

          <div class="col-span-2 mt-6">
            <div class="p-2">
              <span class="mr-20 text-3xl tracking-widest font-bold">
                {{ word.word }}
              </span>

              <span class="text-3xl tracking-widest font-bold"
                >( {{ word.language.name }} )</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right max-w-screen-lg mx-auto mt-2 rounded-xl">
      <button
        class="border-4 border-gray-600 bg-red-500 hover:bg-green-600
                       transition ease-in-out duration-700
                       transform hover:scale-110
                      font-normal text-black-200 hover:text-white py-4 px-10  rounded"
        @click="nextOne"
      >
        N e x t
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      words: []
    };
  },

  methods: {
    playSound(url) {
      var audio = new Audio(url);
      audio.play();
    },

    nextOne() {
      axios
        .get("https://languageapp.watchtheprogress.com/api/word")
        .then(response => (this.words = response.data.word));
    }
  },

  mounted() {
    this.nextOne();
  }
};
</script>

<style scoped></style>
