import Vue from "vue";
import Vuex from "vuex";
// import AudioRecoder from "vue-audio-recorder"

Vue.use(Vuex);

// Vue.use(AudioRecoder)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});
